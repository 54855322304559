import type { CSSProperties } from 'react';

export const fontRoboto14 : CSSProperties = {
  fontFamily : 'Roboto',
  fontSize   : '14px',
  fontStyle  : 'normal',
  fontWeight : 400,
  lineHeight : 1.57,
};

export const fontRoboto16: CSSProperties = {
  fontFamily : 'Roboto',
  fontSize   : '16px',
  fontStyle  : 'normal',
  fontWeight : 400,
  lineHeight : '24px',
};

export const fontRoboto20: CSSProperties = {
  fontFamily : 'Roboto',
  fontSize   : '20px',
  fontStyle  : 'normal',
  fontWeight : 400,
  lineHeight : 1.4,
};

export const fontRoboto32 : CSSProperties = {
  fontFamily : 'Roboto',
  fontSize   : '32px',
  fontStyle  : 'normal',
  fontWeight : 500,
  lineHeight : 1.125,
};

export const fontRoboto48 : CSSProperties = {
  fontFamily : 'Roboto',
  fontSize   : '48px',
  fontStyle  : 'normal',
  fontWeight : 500,
  lineHeight : 1.16,
};

export const fontRoboto96 : CSSProperties = {
  fontFamily : 'Roboto',
  fontSize   : '96px',
  fontStyle  : 'normal',
  fontWeight : 500,
  lineHeight : 1.16,
};
