// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CFKD{display:flex;align-items:center;justify-content:center;width:100%;height:100%}", "",{"version":3,"sources":["webpack://./src/app/App.module.scss"],"names":[],"mappings":"AAAA,MACI,YAAA,CACA,kBAAA,CACA,sBAAA,CAEA,UAAA,CACA,WAAA","sourcesContent":[".loader {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    width: 100%;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "CFKD"
};
export default ___CSS_LOADER_EXPORT___;
