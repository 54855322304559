/* eslint-disable @typescript-eslint/no-unnecessary-condition,no-console */

import { LogLevelDesc } from 'loglevel';

declare global { interface Window { _env_: Record<string, string>; } }

type Env = Readonly<{
  isDev: boolean;
  isProd: boolean;
  logLevel: LogLevelDesc;
  reactUrl: string;
  publicUrlOrPathWithTrailingSlash: string;
  feedbackUrl: string;
  kloudEnv?: 'preview' | 'dev-preview' | 'stage';
}>

const getEnv = (): Env => {
  const env: Env = {
    isDev                            : window._env_.NODE_ENV === 'development',
    isProd                           : window._env_.NODE_ENV === 'production',
    logLevel                         : window._env_.KLOUD_LOG_LEVEL as LogLevelDesc,
    reactUrl                         : window._env_.KLOUD_RT_URL,
    publicUrlOrPathWithTrailingSlash : window._env_.KLOUD_PUBLIC_URL_OR_PATH,
    feedbackUrl                      : window._env_.KLOUD_FEEDBACK_URL,
    kloudEnv                         : window._env_.KLOUD_ENV as Env['kloudEnv'],
  };

  return env;
};

/** @internal */
export const env = getEnv();
