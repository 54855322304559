import React from 'react';
import { createRoot } from 'react-dom/client';
import {AppComponent} from 'src/app';
import 'src/scss/index.global.scss';

const container = document.getElementById('root');
if (!container) throw new Error('Root not found');
const root = createRoot(container);

root.render(<AppComponent />);
