import React, { FC, useEffect, useState } from 'react';
import { styles, logger } from './Loader.gen';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

type LoaderComponentProps = {
  readonly title?: string | undefined;
  readonly className?: string | undefined;
}

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

export const LoaderComponent: FC<LoaderComponentProps> = (props): JSX.Element | null => {
  const {
    title,
    className,
  } = props;

  return (
    <div className={className}>
        <Spin delay={300} indicator={antIcon} tip={title} size={"large"}>
          <div className={styles.content} />
        </Spin>
    </div>
  );
};
