// FIXME: DELETE ME
// TODO: DELETE ME
import log, { Logger, LoggingMethod } from 'loglevel';
import { env } from 'src/env/getEnv';

const  originalFactory = log.methodFactory;
log.methodFactory = (methodName, logLevel, loggerName): LoggingMethod => {
  const rawMethod: LoggingMethod = originalFactory(methodName, logLevel, loggerName);

  return (...message: unknown[]): void => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    rawMethod(`[${methodName[0].toUpperCase()}] ${String(loggerName)}:`, ...message);
  };
};

log.setLevel(env.logLevel);

export const createSimpleLogger = (options: SimpleLoggerManagerOptions): Logger => log.getLogger(options.domain ?? '');

export type SimpleLoggerManagerOptions = {
  readonly domain?: string | null;
};
