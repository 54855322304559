import { GlobalOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import React from 'react';
import i18n from 'src/app/i18n';

const { Option } = Select;
const key = 'locale';
export const ruLanguage = 'ru';
export const enLanguage = 'en';

export const LanguageSelector = () => {
  const handleChange = (value: string) => {
    void i18n.changeLanguage(value);
    localStorage.setItem(key, value);
  };

  return (
    <Select
      suffixIcon={<GlobalOutlined/>}
      style={{ width: 64 }}
      placeholder='Borderless'
      variant='borderless'
      defaultValue={i18n.language}
      onChange={handleChange}
    >
      <Option value= {ruLanguage}>
      RU
      </Option>
      <Option value={enLanguage}>
      EN
      </Option>
    </Select>
  );
};

export const getcarenlanguage = (): string => localStorage. getItem(key) ?? ruLanguage;
