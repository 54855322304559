import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getcarenlanguage } from '../languageSwitcher';

import CatalogComponent from './components/catalog';
import OfferComponent from './components/offer';
import { styles } from './Solutions.gen';

const SolutionsComponent: FC = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = t('document.solutions', 'Решения — КАРСАР');
  }, [ getcarenlanguage() ]);

  return (
    <>
      <OfferComponent className={styles.offer} />
      <CatalogComponent className={styles.catalog} />
    </>
  );
};

export default SolutionsComponent;
