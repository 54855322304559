import { Button, Typography, Image } from 'antd';
import classNames from 'classnames';
import catalogPng from 'public/img/catalog.png';
import catalogPreviewPng from 'public/img/preview/catalog.png';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { fontRoboto16 } from 'src/fontsJs/font';
import { PropsWithClassName } from 'src/types/types';

import { styles } from './Catalog.gen';

const { Title, Text } = Typography;

const titleStyle : React.CSSProperties = {
  color : 'white',
};

const descriptionStyle : React.CSSProperties = {
  ...fontRoboto16,
  color : 'white',
};

const CatalogComponent: FC<PropsWithClassName> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <section className={classNames(styles.catalog, className)}>
      <Title
        className={classNames('renderNewLine', styles.fg, styles.title)}
        level={3}
        style={titleStyle}
      >
        {t('catalog.title', 'Каталог')}
      </Title>

      <Text
        className={classNames('renderNewLine', styles.fg, styles.description)}
        style={descriptionStyle}
      >
        {t('catalog.subtitle', 'Предлагаем высокотехнологичные интегрированные продукты и услуги  \n для решения задач, требующих особого подхода к исследованию скважин.')}
      </Text>

      <a href={'https://klowd.ru/api/Storage/Remote/Blobs/Download?token=xuzuqiybeyc85x6inj93wqepp4gsxerp4gopy1ip5wxkbtj76559iingd113fhn8wts75abdszigzuf7mznf57roizyd5xegrw4jp18sp5g56w937gtnokgtm49x3d66eqwsdeazxxzue'} target='_blank'>
        <Button
          className={styles.fg}
          style={fontRoboto16}
          type={'primary'}
          size={'large'}
        >
        {t("catalog.button", "Скачать")}
        </Button>
      </a>

      <div className={classNames(styles.imgWrapper, styles.bg)}>
        <Image
          className={styles.img}
          preview={false}
          height={'15.2em'}
          src={catalogPng}
          placeholder={
            <Image
              className={styles.img}
              preview={false}
              src={catalogPreviewPng}
              height={'15.2em'}
            />
          }
        />
      </div>
    </section>
  );
};

export default CatalogComponent;
