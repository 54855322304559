import { ArrowRightOutlined } from '@ant-design/icons';
import { Col, Row, Card, Typography } from 'antd';
import classNames from 'classnames';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { fontRoboto20, fontRoboto32 } from 'src/fontsJs/font';
import { PropsWithClassName, Routes } from 'src/types/types';

import { styles } from './Offer.gen';

const { Title, Text } = Typography;

type ItemProps = Readonly<{
  title: React.ReactNode;
  mark: string;
  description: React.ReactNode;
  link: string;
}>


const OfferComponent: FC<PropsWithClassName> = ({ className }) => {
  const { t } = useTranslation();
  const items: ItemProps[] = [ {
    title       : <Trans i18nKey='offer.title-equipment'>Аппаратура и оборудование</Trans>,
    mark        : '(6)',
    description : <Trans i18nKey='offer.description-equipment'>Технологичность, инновационность, надежность, легкость эксплуатациии простота обслуживания.</Trans>,
    link        : Routes.equipment,
  }, {
    title       : <Trans i18nKey='offer.title-software'>Программное обеспечение</Trans>,
    mark        : '(4)',
    description : <Trans i18nKey='offer.description-software'>Лучшие продукты для решения бизнес-задач современного геофизического предприятия.</Trans>,
    link        : Routes.software,
  }, {
    title       : <Trans i18nKey='offer.title-service'>Услуги</Trans>,
    mark        : '(7)',
    description : <Trans i18nKey='offer.description-service'>Наша цель – удовлетворение спроса российского нефтегазового сектора в высокотехнологичном геофизическом сервисе.</Trans>,
    link        : Routes.services,
  }, {
    title       : <Trans i18nKey='offer.title-trainingcourses'>Обучение</Trans>,
    mark        : '(4)',
    description : <Trans i18nKey='offer.description-trainingcourses'>Развитие компетенций и культуры производства при работе с Hi-tech ГИС.</Trans>,
    link        : Routes.education,
  } ];
  return (
    <section className={classNames(styles.offer, className)}>
      <Title
        className={'renderNewLine'}
        level={2}
      >
        {t('offer.solutions-title', 'Правильные решения')}
      </Title>

      <Text
        className={classNames('renderNewLine', styles.description)}
        style={fontRoboto20}
      >
        {t('offer.solutions-subtitle', 'Мы консолидировали весь опыт отечественного и мирового геофизического приборостроения для того, \n чтобы Вы получали выгоду от своих инвестиций без санкционных рисков и ограничений.')}
      </Text>

      <div className={styles.cardGrid}>
        <Row gutter={[ 20, 40 ]}>
          {
            items.map((x, key) => (
              <Col span={12} key={key}>
                <Link to={x.link}>
                  <Card
                    className={styles.card}
                    hoverable
                    headStyle={{ padding: '0.6em 0.8em' }}
                    bodyStyle={{ display: 'flex', alignItems: 'center', padding: '1em' }}
                    title={
                      <>
                        <Title className={styles.itemTitle} level={4} style={fontRoboto32}>{x.title}</Title>
                        <span className={styles.itemMark} style={fontRoboto32}>{x.mark}</span>
                      </>
                    }
                    bordered={false}
                  >
                    <Text className={styles.cardDescription}>{x.description}</Text>
                    <ArrowRightOutlined className={styles.itemArrow} />
                  </Card>
                </Link>
              </Col>
            ))
          }
        </Row>
      </div>
    </section>
  );
};

export default OfferComponent;
