export type PropsWithClassName = {
  readonly className?: string | undefined;
}

export enum Routes {
  equipment = '/equipment',
  company   = '/company',
  contacts  = '/contacts',
  education = '/education',
  software  = '/software',
  services  = '/services',
  solutions = '/solutions',
  policy    = '/policy',
  root      = '/',
  any       = '*',
}
