import React from 'react';
import { Route, createBrowserRouter, createRoutesFromElements, Navigate } from 'react-router-dom';
import SolutionsComponent from 'src/components/solutions';
import { env } from 'src/env/getEnv';
import { Routes } from 'src/types/types';

const MainComponent      = React.lazy(() => import('src/components/main/Main'));
const EquipmentComponent = React.lazy(() => import('src/components/equipment/Equipment'));
const CompanyComponent   = React.lazy(() => import('src/components/company/Company'));
const ContactsComponent  = React.lazy(() => import('src/components/contacts/Contacts'));
const EducationComponent = React.lazy(() => import('src/components/education/Education'));
const SoftwareComponent  = React.lazy(() => import('src/components/software/Software'));
const ServicesComponent  = React.lazy(() => import('src/components/services/Services'));
const LayoutComponent    = React.lazy(() => import('src/components/layout/Layout'));
const PolicyComponent    = React.lazy(() => import('src/components/policy/Policy'));

const _router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={Routes.root} element={<LayoutComponent />}>
      <Route path={Routes.equipment} element={<EquipmentComponent/>} />
      <Route path={Routes.company}   element={<CompanyComponent/>} />
      <Route path={Routes.contacts}  element={<ContactsComponent/>} />
      <Route path={Routes.education} element={<EducationComponent/>} />
      <Route path={Routes.software}  element={<SoftwareComponent/>} />
      <Route path={Routes.solutions} element={<SolutionsComponent />} />
      <Route path={Routes.services}  element={<ServicesComponent/>} />
      <Route path={Routes.policy}    element={<PolicyComponent/>} />
      <Route path={Routes.root}      element={<MainComponent/>} />
      <Route path={Routes.any}       element={<Navigate to={Routes.root} replace/>} />
    </Route>
  ), {
    basename : env.publicUrlOrPathWithTrailingSlash,
  }
);

const router = {
  instance : _router,
};

export default router;
