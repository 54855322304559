import { Watermark } from 'antd';
import React, { FC, Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import { env } from 'src/env/getEnv';

import { styles } from './App.gen';
import { LoaderComponent } from './components/loader';
import router from './router';

const AntdThemeProvider = React.lazy(() => import('src/contexts/AntdThemeProvider'));

export const AppComponent: FC = (): JSX.Element => (
  <React.StrictMode>
    <Suspense fallback={<LoaderComponent title={'Загружаю...'} className={styles.loader} />}>
      <AntdThemeProvider>
        <Watermark
          className='fill-parent'
          content={env.kloudEnv}
        >
          <RouterProvider
            router={router.instance}
          />
        </Watermark>
      </AntdThemeProvider>
    </Suspense>
  </React.StrictMode>
);
