//
//  !!! IMPORTANT !!!
//     DO NOT EDIT
//
//  A placeholder file.
//
import { createSimpleLogger } from 'src/logger/createSimpleLogger';

import styles from './Offer.module.scss';

const logger = createSimpleLogger({ domain: '<%= currentFolderName %>' });

export {
  styles,
  logger
};
