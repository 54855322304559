// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VksM{display:flex;flex-direction:column;align-items:center;position:relative;height:15.2em}.eA_G{padding-top:1.25em}.B5iQ{text-align:center;margin-top:1em;margin-bottom:1.5em}.exwP{position:absolute;background-repeat:no-repeat;height:15.2em}.YTJw{border-radius:1em}.ulFb{z-index:2}.rv32{z-index:1}", "",{"version":3,"sources":["webpack://./src/components/solutions/components/catalog/Catalog.module.scss"],"names":[],"mappings":"AAAA,MACC,YAAA,CACG,qBAAA,CACA,kBAAA,CACH,iBAAA,CAEA,aAAA,CAGD,MACC,kBAAA,CAGD,MACC,iBAAA,CACA,cAAA,CACA,mBAAA,CAGD,MACC,iBAAA,CAEA,2BAAA,CACA,aAAA,CAGD,MACI,iBAAA,CAGJ,MACC,SAAA,CAGD,MACC,SAAA","sourcesContent":[".catalog {\n\tdisplay: flex;\n    flex-direction: column;\n    align-items: center;\n\tposition: relative;\n\n\theight: 15.2em;\n}\n\n.title {\n\tpadding-top: 1.25em;\n}\n\n.description {\n\ttext-align: center;\n\tmargin-top: 1em;\n\tmargin-bottom: 1.5em;\n}\n\n.imgWrapper {\n\tposition: absolute;\n\n\tbackground-repeat: no-repeat;\n\theight: 15.2em;\n}\n\n.img {\n    border-radius: 1em;\n}\n\n.fg {\n\tz-index: 2;\n}\n\n.bg {\n\tz-index: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"catalog": "VksM",
	"title": "eA_G",
	"description": "B5iQ",
	"imgWrapper": "exwP",
	"img": "YTJw",
	"fg": "ulFb",
	"bg": "rv32"
};
export default ___CSS_LOADER_EXPORT___;
