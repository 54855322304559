// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UMkv{padding:4em}", "",{"version":3,"sources":["webpack://./src/app/components/loader/Loader.module.scss"],"names":[],"mappings":"AAAA,MACI,WAAA","sourcesContent":[".content {\n    padding: 4em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "UMkv"
};
export default ___CSS_LOADER_EXPORT___;
